<div fileDragEnter class="w-100 actionButtonWrapper" #actionWrapper [formGroup]="parentForm">
  <div class="d-flex flex-row justify-content-start mobile-space hide-scroll" #actionBox> <!-- C153176-5273: add reference for p-overlay -->
    <!-- C153176-5273: add disable-nclick directive to prevent multiple consecutive sends due to user N clicks -->
    <button disable-nclick class="d-flex flex-column button justify-content-center" id="sendButton" #sendButton [disabled]="isSent"
       (click)="sendInquiry($event, inquiryId, inquiryAction, suggestionConvId, notes)" *ngIf="buttons.sendButton.visible" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/send.svg" class="p-1">
      <span class="mt-auto">SEND</span>
    </button>

    <!-- Resolve Button: Starts-->
    <button class="d-flex flex-column justify-content-center button selected" (click)="markResolve(lock, lockedBy)"
         *ngIf="inquiryAction !== 'Forward' && buttons.resolveButton.visible && resolve && !hideOptionsForTaskize" id="resolveButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/resolve.svg" class="p-1">
      <span class="mt-auto">RESOLVE</span>
    </button>
    <button class="d-flex flex-column justify-content-center button" (click)="markResolve(lock, lockedBy)"
         *ngIf="inquiryAction !== 'Forward' && buttons.resolveButton.visible && !resolve && !hideOptionsForTaskize" id="resolveButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/resolve.svg" class="p-1">
      <span class="mt-auto">RESOLVE</span>
    </button>
    <input type="hidden" id="resolveFlg" #resolveFlag value="N" />
    <!-- Resolve Button: Ends-->

    <button class="d-flex flex-column justify-content-center button" (click)="saveDraft($event, selDraftId, notes)"
        *ngIf="buttons.saveButton.visible && !hideOptionsForTaskize" id="saveButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/save.svg" class="p-1">
      <span class="mt-auto">SAVE</span>
    </button>
    <!-- C153176-5343: enable drag-drop at ATTACH:
    <div class="uploadfilecontainer mobile-upload-hide" (click)="uploadFiles.click()" appDragDrop (onFileDropped)="fileEvent($event)"></div>
    -->
    <input name="files" id="files" type="file" multiple="true" style="display:none" #uploadFiles (change)="fileEvent($event.target.files)"
    />
    <!-- C153176-5343: enable drag-drop at ATTACH -->
    <!-- Hode attach icon for Mobile -->
    <button [ngClass]="borderDragDropHighlight? 'attach-field-drop-target' :''" class="d-flex flex-column justify-content-center button attach-field-drop-text" (click)="uploadFile()" 
    appDragDrop (onFileDropped)="fileEvent($event)"
       *ngIf="buttons.attachButton.visible && !isMobile" id="attachButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/attach.svg" class="p-1">
      <span class="mt-auto">ATTACH</span>
      <span class="attach-field-drop-popup" [ngStyle]="{'visibility': borderDragDropHighlight? 'visible':'hidden'}">Drop files here to upload</span>
    </button>
    <button [ngClass]="borderDragDropHighlight? 'attach-field-drop-target' :''" class="d-flex flex-column justify-content-center button attach-field-drop-text" (click)="onReAge()" 
       *ngIf="buttons.reAgeButton.visible && !isMobile && displayReAge" id="reAgeButton">
      <img src="assets/context/re-age.svg" class="p-1" class="reAgeIcon" alt="Re-Age">
      <span class="mt-auto">RE-AGE</span>
      <span class="attach-field-drop-popup" [ngStyle]="{'visibility': borderDragDropHighlight? 'visible':'hidden'}">Re-Age</span>
    </button>

    <!-- Format Button : Starts-->
    <button class="d-flex flex-column justify-content-center button mobile-view-hide selected" (click)="fotmatToggle()"
         *ngIf="buttons.formatButton.visible && formatTog" id="formatButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/format.svg" class="p-1">
      <span class="mt-auto">FORMAT</span>
    </button>
    <button class="d-flex flex-column justify-content-center button mobile-view-hide" (click)="fotmatToggle()"
         *ngIf="buttons.formatButton.visible && !formatTog" id="formatButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/format.svg" class="p-1">
      <span class="mt-auto">FORMAT</span>
    </button>
    <app-re-age #reAge></app-re-age>
    <!-- Format Button : Ends-->

    <!-- Important button: Starts-->
    <button class="d-flex flex-column justify-content-center button selected" (click)="markImportant(true)"
         *ngIf="buttons.importantButton.visible && imp" id="importantButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/important.svg" class="p-1">
      <span class="mt-auto">IMPORTANT</span>
    </button>
    <button class="d-flex flex-column justify-content-center button" (click)="markImportant(true)"
        *ngIf="buttons.importantButton.visible && !imp" id="importantButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/important.svg" class="p-1">
      <span class="mt-auto">IMPORTANT</span>
    </button>
    <input type="hidden" id="urgentFlg" #urgentFlag value="N" />
    <!-- Important button: Ends-->

    <!-- Secure button: Starts-->
    <button class="d-flex flex-column justify-content-center button  mobile-view-hide selected" (click)="markSecure()"
          *ngIf="isVisible && buttons.secureButton.visible && secure" id="secureButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/secure.svg" class="p-1">
      <span class="mt-auto" *ngIf="isVisible">SECURE</span> 
    </button>
    <button class="d-flex flex-column justify-content-center button  mobile-view-hide" (click)="markSecure()"
          *ngIf="isVisible && buttons.secureButton.visible && !secure" id="secureButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/secure.svg" class="p-1">
      <span class="mt-auto" *ngIf="isVisible">SECURE</span> 
    </button>
    <input type="hidden" id="secureFlag" #secureFlag value="N" />
    <!-- Secure button: Ends-->

    <!-- Approval button: Starts-->
    <button #apprReqdBtn class="d-flex flex-column justify-content-center button  mobile-view-hide selected" (click)="markApprovalRequired()"
          *ngIf="parentForm.controls.imageAttach.value && buttons.approvalButton.visible && approvalReq" id="approvalButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/approval.svg" class="p-1 followUp">
      <span class="mt-auto" *ngIf="parentForm.controls.imageAttach.value">APPROVAL</span> 
    </button>
    <button #apprReqdBtn class="d-flex flex-column justify-content-center button  mobile-view-hide" (click)="markApprovalRequired()"
          *ngIf="parentForm.controls.imageAttach.value && buttons.approvalButton.visible && !approvalReq" id="approvalButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/approval.svg" class="p-1 followUp">
      <span class="mt-auto" *ngIf="parentForm.controls.imageAttach.value">APPROVAL</span> 
    </button>
    <input type="hidden" id="apprReqdFlag" #apprReqdFlag value="N" />
    <!-- Approval button: Ends-->

    <!-- FollowUp button: Starts-->
    <button class="d-flex flex-column justify-content-center button  mobile-view-hide followUpBtn selected" (click)="markFollowUp()"
          *ngIf="buttons.followupButton.visible && followup && !hideOptionsForTaskize" id="followupButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/Inbox/follow_up_icon_u112.svg" class="p-1 followUp">
      <span class="mt-auto">FOLLOW UP</span> 
    </button>
    <button class="d-flex flex-column justify-content-center button  mobile-view-hide followUpBtn" (click)="markFollowUp()"
          *ngIf="buttons.followupButton.visible && !followup && !hideOptionsForTaskize" id="followupButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/Inbox/follow_up_icon_u112.svg" class="p-1 followUp">
      <span class="mt-auto">FOLLOW UP</span> 
    </button>
    <input type="hidden" id="followupFlag" #followupFlag value="N" />
    <!-- FollowUp button: Ends-->

    <button class="d-flex flex-column justify-content-center button sign-section  mobile-view-hide" (click)="showSignaturePopup($event, signPop)"
          *ngIf="buttons.signatureButton.visible" id="signatureButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/signature.svg" class="p-1">
      <span class="mt-auto">SIGNATURE</span>
    </button>
     <!-- Jira : C153176-4959 Mobile : Schedule for later -->
    <button class="d-flex flex-column button justify-content-center  scheduleBtn" (click)="scheduleInquiry($event, inquiryId, inquiryAction, suggestionConvId)"
           *ngIf="buttons.scheduleButton.visible && !hideOptionsForTaskize" id="scheduleButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/schedule.svg" class="p-1">
      <span class="mt-auto">SCHEDULE</span>
    </button>

    <button class="d-flex flex-column justify-content-center button  mobile-view-hide templateBtn" (click)="showTemplatesPopup($event, tempPop)"
           *ngIf="buttons.templateButton.visible && !hideOptionsForTaskize" id="templateButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/templates.svg" class="p-1">
      <span class="mt-auto">TEMPLATES</span>
    </button>

    <!-- C153176-5578: Reposition Cancel button -->
    <button class="d-flex flex-column justify-content-center button" (click)="cancel($event, inquiryId, selDraftId)"
      *ngIf="buttons.cancelButton.visible" id="cancelButton" #actionBtn> <!-- C153176-5273: flag to show/hide button -->
      <img src="assets/newMessage/cancel.svg" class="mobile-cancel-btn">
      <span class="mt-auto">CANCEL</span>
    </button>
    <!-- C153176-5273: ellipsis to make action bar responsive-->
    <button class="d-flex flex-column justify-content-center button" *ngIf="buttons.ellipsisButton.visible" id="ellipsisButton" (click)="showActionMenu($event, actionMenuOptions)" #actionBtn>
      <img src="assets/newMessage/ellipse.svg" class="p-1">
    </button>

    <!--C153176-5273: ellipsis-triggered context menu-->
    <p-overlayPanel [styleClass]="'action-overlay-options action-wrapper'" id="actionMenuOptions" #actionMenuOptions [appendTo]="actionBox">
        <p-listbox [options]="contextmenuItems" (click)="onActionMenu($event, actionMenuOptions, tempPop, signPop)">
            <ng-template let-menuOption pTemplate="item">
              <div class="action-menu-items">
                <img src="assets/newMessage/{{menuOption.src}}.svg" class="p-1" [ngClass]="{'followUpCls' : menuOption.label === 'FOLLOW UP'}">
                <span class="mt-auto">{{menuOption.label}}</span>
              </div>
            </ng-template>
        </p-listbox>
    </p-overlayPanel>
  </div>
</div>

<!-- signature Popup  start-->
<p-overlayPanel #signPop [appendTo]="'body'" styleClass="signaturePopup">
  <div class="signatureBody">
    <ul>
      <!-- <ng-scrollbar class="my-scrollbar" [invertY]="false" [trackX]="false" [compact]="false" [shown]="'native'" [autoUpdate]="true"> -->
      <li *ngFor="let sign of signatures">
        <div class="d-flex flex-row">
          <div class="fieldWrapper">
            <div class="name">{{sign.label}}</div>
            <div class="desc" [innerHTML]="sign.value"></div>
          </div>
          <div class="icon">
            <button class="d-flex flex-column button justify-content-center" (click)="onSignatureChange(sign.label, signPop)">
                <img src="assets/newMessage/add-blue.svg" class="p-1">
                <span>ADD</span>
              </button>
          </div>
        </div>
      </li>
      <!-- </ng-scrollbar> -->
    </ul>
  </div>
</p-overlayPanel>
<!-- signature Popup end-->

<!-- signature Popup start-->
<p-overlayPanel #tempPop [appendTo]="'body'" styleClass="templatePopup">
  <div class="templatesBody">
    <ul>
      <li *ngFor="let template of templates">
        <div class="d-flex flex-row">
          <div class="fieldWrapper">
            <div class="name">{{template.label}}</div>
            <div class="desc">{{template.value}}</div>
          </div>
          <div class="icon">
            <button class="d-flex flex-column button justify-content-center" (click)="onTemplateChange(template.label, tempPop)">
                    <img src="assets/newMessage/add-blue.svg" class="p-1">
                    <span>ADD</span>
                  </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</p-overlayPanel>
<!-- signature Popup  end-->

<!-- Save Draft Confirmation Pop up - Start -->
<p-confirmDialog #cd header="Confirm Action" width="425" rejectIcon="No" acceptIcon="No" [styleClass]="'savedraft-section'">
  <p-footer>
    <button type="button" pButton label="No" (click)="cd.accept()" style="margin-right: 10px;"></button>
    <button type="button" pButton label="Yes" (click)="cd.reject()"></button>
</p-footer>
</p-confirmDialog>

<!-- Save Draft Confirmation Pop up - End -->

<!-- Schedule Message Pop up - Start -->
<schedule-message (timeEvent)="onTimeChanged($event)"></schedule-message>
<!-- Schedule Message  Pop up - End -->

<p-toast position="center" (onClose)="onReject()" styleClass="custom-toast" key="custom" [baseZIndex]="5000"></p-toast>

<p-toast styleClass="custom-toast" key="sendInquiry" ></p-toast>

<!-- Passpord Protection popup Start-->

<p-dialog [(visible)]="displayPasswordProtection" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'attachmentModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
  [style]="{'max-width':'535px','width':'40%'}">

  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between attachment-header">
      <h6 class="attachment-title">Attachments</h6>
      <button type="button" (click)="cancelPasswordProtection()" class="d-flex flex-row close qma-text">
      <img src="assets/Inbox/close_icon_u73.svg">
      <h6>CLOSE</h6>
  </button>
    </div>
  </p-header>
  <form class="form-horizontal" [formGroup]="passwordProtectionForm" (ngSubmit)="saveAllAttachedFiles()">
    <div id="createnew" class="mt-1">

      <div class="d-flex flex-row align-items-start">
        <label class="lblAttachment" [attr.for]="'selectFiles'"> Select Files for Password Protection </label>
      </div>

      <div class="custom-control custom-checkbox">
        <input type="checkbox" [attr.id]="'selectAll'" name="selectAll" #attachmentSelectAllCheckBox value="selectAll" (change)="selectAllFiles($event)"
          class="custom-control-input">
        <label class="custom-control-label" [attr.for]="'selectAll'">Select All</label>
      </div>
      <div class="scrollFileList">
        <div #showAllSupportedUnSecuredFiles>
          <div class="custom-control custom-checkbox" *ngFor="let fileName of showAllSupportedUnSecuredFilesList;  let i = index">
            <input type="checkbox" name="{{fileName.fileName}}" [attr.id]="'file'+i" value="{{fileName.fileName}}" class="custom-control-input">
            <label class="custom-control-label" [attr.for]="'file'+i"> {{ fileName.fileName }}</label>
          </div>
        </div>
        <div #showAllSupportedSecuredFiles>
          <div class="custom-control custom-checkbox" *ngFor="let fileName of showAllSupportedSecuredFilesList; let i = index">
            <input type="checkbox" name="{{fileName.fileName}}" [attr.id]="'file'+i" value="{{fileName.fileName}}" class="custom-control-input" disabled checked>
            <label class="custom-control-label" [attr.for]="'file'+i"> {{ fileName.fileName }}</label>
          </div>
        </div>
        <div #showAllUnSupportedFiles>
          <div class="custom-control custom-checkbox" *ngFor="let fileName of showAllUnSupportedFilesList; let i = index">
            <input type="checkbox" name="{{fileName.fileName}}" [attr.id]="'file'+i" value="{{fileName.fileName}}" class="custom-control-input" disabled>
            <label class="custom-control-label" [attr.for]="'file'+i"> {{ fileName.fileName }}</label>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row align-items-start">
        <div class="">
          <input type="password" placeholder="Enter Password" formControlName="fileInfo" class="w-75 form-control customText" pInputText
            #fileInfo/>
        </div>
        <div class="custom-checkbox custom-control">
          <input type="checkbox" (click)="showPassword($event)" class="custom-control-input" [attr.id]="'ShowPassword'">
          <label class="custom-control-label " [attr.for]="'ShowPassword'"> Show Password:</label>
        </div>
      </div>
      <span style="margin-left: 10px;color:red;" [innerHTML]="errorMsg"></span>
    </div>
    <p-footer>
      <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 footer-buttons-holder justify-content-end">
        <button type="submit" class="btn search-btn mr-4">Continue</button>
        <button type="reset" class="btn clear-btn" (click)="cancelPasswordProtection()">Cancel</button>
      </div>
    </p-footer>
  </form>
</p-dialog>
<!-- Passpord Protection popup End-->


<p-dialog [(visible)]="displayError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'blacklistModel'"
  [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Information</h6>
      <div class="d-flex">
        <div class="d-flex">
          <div class="d-flex">
            <img src="assets/Inbox/info-icon.svg" class="icon-setting-out"  (mouseover)="showUploadFilesPanel($event, info_upload_files)" (mouseout)="info_upload_files.hide()"/> <!-- (mouseout)="info_upload_files.hide()" -->
            <p-overlayPanel [styleClass]="'icon-info-upload-files inquiry_rules_info'" [appendTo]="'body'" #info_upload_files [baseZIndex]="9999">
                <div class="flex-row mb-0.3" style="font-family: 'Interstate'">
                  <div class="info-content" style="font-family: 'Interstate';font-weight: bold;">
                    List of extensions restricted to upload:-
                  </div>
                    <div class="info-content">{{qmaConstant?.QMA_UPLOAD_FILES_INFO}}</div>
                   
                </div>
                <div class="flex-row mb-0.3  mt-2"  style="font-family: 'Interstate';">
                  <div class="info-content" style="font-family: 'Interstate';font-weight: bold;">
                    List of  restricted characters in file name to upload:-
                  </div>
                  <div style="font-family: 'Interstate';" class="info-content">
                    {{Qma_blocked_file_name_chars}}
                  </div>
                </div>
            </p-overlayPanel>
          </div>
            <button type="button" (click)="closeModal('error')" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg">
                <h6>CLOSE</h6>
            </button>
        </div>
       
    </div>
    </div>
  </p-header>
  <div class="success-msg" style="font-weight: bold;" *ngIf="errMsg">
     Below uploaded file extensions are not supported in QMA. Please refer to the information icon for more details.
  </div>
  <div class="success-msg" [innerHTML]="errMsg"></div>
  <div class="success-msg" style="font-weight: bold;" *ngIf="errMsgRestrcitedCharsInFilename">
    Below uploaded file name contains special characters which is replaced by _  Please refer to information icon for more details
  </div>
  <div class="success-msg" [innerHTML]="errMsgRestrcitedCharsInFilename"></div>
  <div class="success-msg" style="font-weight: bold;" *ngIf="errMsgMultipleDotsInFilename">
    Below uploaded file name contains multiple . characters which is replaced by _
  </div>
  <div class="success-msg" [innerHTML]="errMsgMultipleDotsInFilename"></div>
</p-dialog>

<!--C153176-5298 |  Confirmation for force Resolve -->
<p-dialog [(visible)]="forceResolveConfirm" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal'"
[closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'max-width':'500px','width':'50%'}">
<p-header>
    <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
        <h6 class="success-title">FORCE RESOLVE</h6>            
        <button type="button" (click)="cancelResolveMark()" class="d-flex flex-row close qma-text">
        <img src="assets/Inbox/close_icon_u73.svg">            
    </button>
    </div>
</p-header>
<div class="mx-2">
    <div class="d-flex flex-row align-items-center mb-4 pb-4">
        <div class="success-msg" [innerHTML]="forceResolveConfirmMsg"></div>
    </div>
</div>
<p-footer>
    <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
        <button class="btn search-btn mr-4" (click)="setResolveMark()">Ok</button>
        <button class="btn clear-btn" (click)="cancelResolveMark()">Cancel</button>
    </div>
</p-footer>
</p-dialog>

<p-dialog [(visible)]="invaliDEmailIdExists" [modal]="true" [responsive]="true" [maximizable]="false" 
[appendTo]="'body'"
[closable]="true" [dismissableMask]="true"  [baseZIndex]="10000" [closeOnEscape]="true" 
width="25%"
[draggable]="true"  [styleClass]="'nlp-modal-pop-up'">
  <p-header>
    Invalid Email Ids
  </p-header>
 <div>
  <h6 style="font-weight: 800;
  font-family: 'InterstateLight';
  font-size: 12px;">
    Please correct below emailIds to proceed :-
  </h6> 
  <div *ngFor="let invalidId of invalidEmailIds" style="font-family: 'Interstate';
  font-size: 12px;">
    {{invalidId}}
  </div>
 </div>
</p-dialog>

<p-dialog [(visible)]="displayApiError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'blacklistModel'"
  [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}" [closable]="false">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Error</h6>
      <button type="button" (click)="closeApiErrorPopup()" class="d-flex flex-row close qma-text">
        <img src="assets/Inbox/close_icon_u73.svg">
        <h6>CLOSE</h6>
    </button>
    </div>
  </p-header>
  <div class="success-msg" style="font-weight: bold;">
    File was not uploaded, please try again later or contact QMA Support
  </div>
  
</p-dialog>