// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  MIS_DASHBOARD: 'XXXX',

  ISSUER: 'https://login.microsoftonline.com/a9e6a0e7-fbc4-4701-96c9-b832b342dbf1/v2.0',
  REDIRECT_URI: 'https://dev.qwyn.deltacapita.net',
  CLIENT_ID: 'aea292bc-abc7-42f7-bef6-6a767bc14fdf',
  SCOPE: 'openid profile api://aea292bc-abc7-42f7-bef6-6a767bc14fdf/sso_application',
  RESPONSE_TYPE: 'code',
  QWYNUI_URL: 'https://dev.qwyn.deltacapita.net',
  QMAAPI_URL: 'https://qmauiapi.dev.qwyn.deltacapita.net',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
